export const environment = {
    production: false,
    appVersion: '',
    envName: 'training',
    chatEndpoint: '',
    participantServiceEndpoint: 'https://participant.connect.ca-central-1.amazonaws.com',
    updateAttributes : 'https://m6jdindpwd.execute-api.ca-central-1.amazonaws.com/training/updateContactAttributes',
    validateToken : 'https://m6jdindpwd.execute-api.ca-central-1.amazonaws.com/training/validateToken',
    ccpUrl : 'https://ac-connect-ae-ca-central-1-training.my.connect.aws/ccp-v2',
    apiKey: '1LmKisf9I25eGhzDPIGM3arKlMHAEGxcaZO74uCd',
    translation: 'https://smkl6yzntg-vpce-0be802902a2226dcc.execute-api.ca-central-1.amazonaws.com/ac-training/'
};
